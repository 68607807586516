import React, { Component } from 'react';
import axios from 'axios';
import AMapLoader from '@amap/amap-jsapi-loader';
import './MapContainer.css';
import iconImage from '../../assets/suqiu_logo.jpg';

window._AMapSecurityConfig = {
    securityJsCode:'4cdb4240417f431e7bbe76f60474a31a「您申请的安全密钥」',
}

class  MapComponent extends Component{
    constructor(){
        super();      
        this.map ={};
        this.AMap = {};

        this.state = {
            width: 1080,
            height: 1920,
        }
        
       
        this.useWindowSize();
        
    }
    

    // 2.dom渲染成功后进行map对象的创建
    componentDidMount(){
        AMapLoader.load({
            key:"16ac2e8fe5767bce43f7f8ebed0bbbe8",                     // 申请好的Web端开发者Key，首次调用 load 时必填
            version:"2.0",              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins:[''],               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap)=>{
            // 禁止显示滚动条
            document.body.style.overflow = 'hidden';

            this.AMap = AMap;
            this.map = new AMap.Map("container",{ //设置地图容器id
                viewMode:"3D",         //是否为3D地图模式
                zoom:5,                //初始化地图级别
                center:[105.602725,37.076636], //初始化地图中心点位置
            });

            this.getContentSize();
            
            this.getData();
        }).catch((res)=>{})
    }

    async getData(){
        let that = this;
        axios.get('https://testminiprogram.suqzp.com/internal/queryHotelLocationInformationList').then(async (res)=>{
            if(res.status === 200 && res.data && (res.data.code === 200 || res.data.code === '200')){
                let list = res.data.data;
                let arr = [];

                for (let i = 0; i < list.length; i++) {
                    if(list[i].hotelLongitude && list[i].hotelLatitude){
                        
                        let image = await that.getImage(list[i].hotelPic);

                        const icon = {
                            type: 'image', // 图标类型，现阶段只支持 image 类型
                            image: image ? image : iconImage, // 图片 url
                            size: [32, 32],// 图片尺寸
                            anchor: 'center', // 图片相对 position 的锚点，默认为 bottom-center 
                        };

                        const text = {
                            content: list[i].hotelName, // 要展示的文字内容
                            direction: 'right', // 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
                            offset: [-1, -3], // 在 direction 基础上的偏移量
                            style: { // 文字样式       
                                fontSize: 14 ,// 字体大小
                                fontWeight: '700',      
                                fillColor: 'red', // 字体颜色  
                                strokeColor: '#fff', // 描边颜色
                                strokeWidth: 2,  // 描边宽度
                            }
                        }

                         // 113.264499,23.130061
                        const labelMarker = new this.AMap.LabelMarker({
                            name: list[i].hotelId, // 此属性非绘制文字内容，仅最为标识使用
                            position: [list[i].hotelLongitude, list[i].hotelLatitude],
                            zIndex: 16,
                            title: list[i].hotelName,
                            icon: icon, // 将第一步创建的 icon 对象传给 icon 属性
                            text: text,// 将第二步创建的 text 对象传给 text 属性
                        });

                        arr.push(labelMarker)
                    }
                    
                }
                
                const labelsLayer = new this.AMap.LabelsLayer({
                    zooms: [3, 20],
                    zIndex: 1000,
                    collision: true,  // 该层内标注是否避让
                    allowCollision: true, // 设置 allowCollision：true，可以让标注避让用户的标注
                });
    
                labelsLayer.add(arr);
                
                this.map.add(labelsLayer);

            }
        }).catch((res)=>{})

        
    }

    // 获取图片信息
    async getImage(image){
        if(!image) return '';

        return new Promise((resolve)=>{
            axios.get(image).then((res)=>{
                resolve(image)
            }).catch((res)=>{
                resolve('')
            })
        })
    }

    // 获取宽高
    getContentSize () {
        const contentWidth = document.documentElement.clientWidth || document.body.clientWidth;
        const contentHeight = document.documentElement.clientHeight || document.body.clientHeight;
        
        this.setState({
            width: contentWidth,
            height: contentHeight
        })
    }

    useWindowSize(){
        let that = this;
        window.addEventListener('resize' , function(){
            that.getContentSize();
        })
    }

    render(){
        // 1.初始化创建地图容器,div标签作为地图容器，同时为该div指定id属性；
        const { width , height} = this.state;
        return (
              <div id="container" className="map" style={{ height, width }} > 
              </div>
          );
    }
}
//导出地图组建类
export default MapComponent;